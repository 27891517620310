const appConfig = {
    apiPrefix: 'https://www.reelfellasapp.com/api',
    // apiPrefix: 'http://localhost:5001/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
